import logo from './logo.svg';
import './App.css';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import { FormCard } from './FormCard';
import Helmet from "react-helmet"

function App() {
    const [openbankOption, setOpenbank] = useState(false);
    const navigate = useNavigate();

    function renderContent() {
        if (openbankOption) {
            return <FormCard />
        }
        else {
            return (
                <>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Entradas para Circlassica |  </title>
                        <meta name="description" content="Compra tu entrada y no te pierdas Circlassica, un nuevo show en el que los temas que marcaron tu vida se fusionan con el circo más salvaje." />
                    </Helmet>
                    <div class="container-btns">
                        <div class="boton-pre boton-clientes">
                            <a class="clientes" href="https://inicioentradas.circlassica.es/espectaculo/circlassica-gran-circo-mundial/CIRCLASSICA2223">
                                <div>COMPRAR <br /> ENTRADAS</div>
                            </a>
                            <a className='click_here'  href="https://inicioentradas.circlassica.es/espectaculo/circlassica-gran-circo-mundial/CIRCLASSICA2223">
                                
                            </a>
                        </div>
                        <div class="boton-pre boton-clientes"  onClick={() => navigate("/ventaopenbank")}>
                            <button> CLIENTES <br /> OPENBANK</button>
                            <a className='click_here'>
                                
                            </a>
                        </div>
                    </div>
                </>
            );
        }

    }
    return (
        <React.Fragment>
            <div class="header">
                <a href='https://rockcircus.show/'>
                    <img src="https://entradas.rockcircus.show/logotipos/diver-logo-desktop.png" alt="" />
                </a>
            </div>
            <div class="blackfriday"><img src="https://firebasestorage.googleapis.com/v0/b/jmdb-production.appspot.com/o/establecimientos%2Fextras%2Fextras%2FFOMNTWOW0L5%2FfvFOMNTWOW0L5%40_UrlIAE__fichero-iae.png?alt=media" alt="" /></div>
            {renderContent()}
        </React.Fragment>

    )
}

export default App;
